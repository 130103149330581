import DatePicker from "vue2-datepicker"
import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required, maxValue } from "vuelidate/lib/validators"
import Loading from '@/components/widgets/Loading.vue'
import moment from 'moment'

export default {
  props: {
    opened: {
        type: Boolean,
        default: false
    },
    mode: {
        type: String
    },
    id: {
        type: String
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
        if (this.id) {
          this.onInitData()
        }
      }
    }
  },
  components: {
    DatePicker,
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      skeleton: false,
      drawerOpened: false,
      items: {},
      form: {
        uuid: null,
        paid_date: new Date(),
        amount: null,
      }
    }
  },
  validations: {
    form: {
      amount: { 
        required
      }
    },
  },
  methods: {
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }

        if (this.form.amount > this.items.bill_nettotal_amt) {
          this.$refs.amount.focus()

          this.onExceptionHandler("ระบุจำนวนเงินที่ต้องการชำระให้ถูกต้อง");
          return
        }
        this.loading = true
        const formData = new FormData()
        formData.append('bill_id', this.items.id)
        formData.append('amount', this.form.amount)
        formData.append('paid_at', moment(this.form.paid_date).format('YYYY-MM-DD'))

        const result = await this.$store.dispatch('Receipts/create', formData, { root: true }) 
        setTimeout(() => {
          this.loading = false  
          if (result.status === 201 || result.status === 200) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(result => {
              this.onCloseDrawer()
            })
          }
        }, 1000)
      } catch (error) {
        this.loading = false 
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onInitData() {
      this.skeleton = true
      const param = {
        _id: this.id
      }
      const result = await this.$store.dispatch('Bills/get', param, { root: true })
      if (result.status === 200) {
        this.skeleton = false
        this.items = result.data
      }
    },
    resetForm() {
      this.form.amount = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    }
  }
}